import React from "react";
import Layout from "../components/Layout";
import TitleAndDesc from "../components/TitleAndDescription";
import Insight from "../components/Insight";
import { useInsightsQuery } from "../hooks/Insights/useInsightsQuery";
//   This is the main insights page

const MainInsights = () => {
	const InsightsData = useInsightsQuery();
	console.log(InsightsData);

	const arrayOfInsights = InsightsData.allWpPost.edges;
	console.log("array of insights", arrayOfInsights);
	return (
		<Layout>
			<div>The search will go here </div>
			<TitleAndDesc title={"Insights"} desc={""} />

			{/* Insights mapped out below */}
			<div>
				{arrayOfInsights.map((insight) => {
					return (
						<Insight
							image={insight.node.featuredImage.node.gatsbyImage}
							title={insight.node.title}
							excerpt={insight.node.excerpt}
							tag={insight.node.tags.nodes[0].name}
							link={insight.node.uri}
						/>
					);
				})}
			</div>
		</Layout>
	);
};

export default MainInsights;
